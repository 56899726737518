import React from "react"
import { Link } from "gatsby"
import {
  oplata,
  oplataMini,
  fiftyPercent,
  play,
  calendar,
  camera,
} from "../assets"
import "./main.css"

const Pay = () => {
  return (
    <div className="flex pt4 pl6-l pl5-m pl4 pr3-m pr2 ">
      <div className="flex flex-column w-60-l w-100   pt6-l mr6-l f4-ns f5 lh-copy">
        <h1 className="flex-wrap w-100-ns w-80 f1-l f2-m f3 ipad__font__header b mb4">
          Скидка 50% на штрафы ПДД
        </h1>
        <Link className=" flex link blue f4-ns f5 ipad__font__text items-center">
          <img alt="play" title="play" src={play} className=" w2 h2 mr3 mb0" />
          <span>Как оплатить штраф со скидкой? </span>
        </Link>
        <p className="mt4 ipad__font__text">
          Статья 811 Адмкодекса позволяет гражданам получить скидку 
          на оплату штрафов в первые 7 рабочих дней после оформления предписания.
        </p>
        <img
          alt="oplataMini"
          title="oplataMini"
          src={oplataMini}
          className="dn-l mb-0"
        />
        <div className="flex">
          <img
            alt="fiftyPercent"
            title="fiftyPercent"
            src={fiftyPercent}
            className="mr4 main__pages__icons"
          />
          <div className="dib">
            <h2 className="f3-l f4-m f5">Как отличить штраф, на который распространяется скидка? </h2>
            <p className="gray ipad__font__text">
              В личном кабинете вы можете посмотреть список непогашенных штрафов: 
              он будет помечен иконкой “50%”.
            </p>
          </div>
        </div>
        <div className="flex">
          <img
            alt="calendar"
            title="calendar"
            src={calendar}
            className="mr4 main__pages__icons"
          />
          <div className="dib">
            <h2 className="f3-l f4-m f5">Сколько времени дается на оплату штрафа со скидкой? </h2>
            <p className="gray ipad__font__text">
              На оплату с дисконтом у вас есть 7 рабочих дней, 
              начиная со дня, когда вынесено постановление. 
              Не считаются выходные и праздничные дни.
            </p>
          </div>
        </div>
        <div className="flex">
          <img
            alt="camera"
            title="camera"
            src={camera}
            className="mr4 main__pages__icons"
          />
          <div className="dib">
            <h2 className="f3-l f4-m f5">Сэкономьте 50% на оплате нарушений с камер</h2>
            <p className="gray ipad__font__text">
              С 9.01.2018 автовладельцы могут воспользоваться скидкой 50%, 
              оплачивая штрафы за нарушения, зафиксированные дорожными фото- 
              и видеосистемами.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-l dn">
        <img
          alt="фото скидка 50 процентов на штраф ПДД в РК"
          title="оплата штрафа ПДД со скидкой 50 процентов"
          src={oplata}
          className="flex-ns mb0 image__size"
        />
      </div>
    </div>
  )
}

export default Pay
